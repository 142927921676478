import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
} from 'reactstrap'
import classnames from 'classnames'

import AnchorLink from 'components/ui/anchor-link'
import Image from 'components/ui/image'

const NavbarCustom = () => {
  const {
    sanityAppearance: {
      tab: { header: headerSection },
    },
  } = useStaticQuery(graphql`
    query HeroHeaderQuery {
      sanityAppearance {
        tab {
          header {
            ...HeaderSection
          }
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 80) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <header className={classnames({ scrolled })}>
      <Container fluid>
        <Navbar dark className="main-navbar" sticky="top" expand="lg">
          {headerSection?.enable && (
            <NavbarBrand
              href="/"
              title="Home"
              className="mr-auto main-navbar--brand"
            >
              <figure className="main-navbar--logo-container m-0 p-0">
                <Image
                  className="main-navbar--logo"
                  data={headerSection?.logo}
                />
              </figure>
            </NavbarBrand>
          )}
          <NavbarToggler
            className={classnames('main-navbar--toggler p-0 w-0', {
              isOpen,
            })}
            onClick={toggle}
          />
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav className="main-navbar--nav mt-4 mt-md-0" navbar>
              {headerSection?.nav.map(item => (
                <NavItem key={`nav-item-${item?._key}`}>
                  <AnchorLink
                    className="nav-link mt-5 mt-lg-0"
                    key={item?._key}
                    type={item?.type}
                    link={item?.link}
                    text={item?.text}
                    target={item?.target}
                    onClick={toggle}
                  />
                  <hr className="nav-item--separator" />
                </NavItem>
              ))}
            </Nav>
            {headerSection?.enable && (
              <NavbarText className="main-navbar--appointment mt-5 mt-lg-0">
                <AnchorLink
                  className="text-item"
                  key={headerSection?.cta?._key}
                  type={headerSection?.cta?.type}
                  link={headerSection?.cta?.link}
                  text={headerSection?.cta?.text}
                  target={headerSection?.cta?.target}
                  enable={headerSection?.cta?.enable}
                />
              </NavbarText>
            )}
          </Collapse>
        </Navbar>
      </Container>
    </header>
  )
}

export default NavbarCustom
