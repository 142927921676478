import PropTypes from 'prop-types'
import React from 'react'
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

import TopBanner from '../top-banner'
import Navbar from '../navbar'
import Footer from '../footer'

const Layout = ({ children }) => {
  return (
    <>
      <TopBanner />
      <Navbar />
      <main>{children}</main>
      <WhatsAppWidget
        message={`¡Hola! 👋🏼 \n\n¿Qué podemos hacer por ti?`}
        sendButton="Enviar"
        placeholder="Escribe tu mensaje"
        textReplyTime=""
        companyName="Soporte"
        phoneNumber="5745906107"
      />
      <Footer />
    </>
  )
}

export default Layout

Layout.defaultProps = {
  children: <></>,
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
}
