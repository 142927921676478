import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Anchor = ({
  type = 'link',
  link,
  text,
  target = '_self',
  className = '',
  onClick,
  enable,
}) => {
  if (!enable) return null
  if (type === 'anchor' || link?.startsWith('#')) {
    return (
      <AnchorLink
        href={link}
        className={className}
        target={target}
        offset="160"
        onClick={onClick}
      >
        {text}
      </AnchorLink>
    )
  }

  if (link?.startsWith('tel:') || link?.startsWith('mailto:')) {
    return (
      <a href={link} className={className} onClick={onClick}>
        {text}
      </a>
    )
  }

  return (
    <Link to={link} className={className} target={target} onClick={onClick}>
      {text}
    </Link>
  )
}
export default Anchor

Anchor.defaultProps = {
  type: 'link',
  link: null,
  text: null,
  target: '_self',
  className: null,
  onClick: null,
  enable: true,
}
Anchor.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  target: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  enable: PropTypes.bool,
}
