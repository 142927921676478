import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import RenderBlock from 'components/ui/block-text'
import Anchor from 'components/ui/anchor-link'

const TopBanner = () => {
  const {
    sanityAppearance: {
      tab: { banner: topBannerSection },
    },
  } = useStaticQuery(graphql`
    query TopBannerQuery {
      sanityAppearance {
        tab {
          banner {
            ...TopBannerSection
          }
        }
      }
    }
  `)

  return (
    <section className="top-banner">
      <Container className="top-banner__main-container">
        <Row className="">
          <Col xs={12} className="text-center mx-0 px-0">
            {topBannerSection?.enable && (
              <Anchor
                className="top-banner__link d-inline"
                text={topBannerSection?.cta.text}
                link={topBannerSection?.cta.link}
                type={topBannerSection?.cta.type}
                target={topBannerSection?.cta.target}
                enable={topBannerSection?.cta?.enable}
              />
            )}
            {topBannerSection?.enable && (
              <RenderBlock
                className="top-banner__title d-inline text-center m-0 pl-1"
                data={topBannerSection?._rawDescription}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default TopBanner
