import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'

import Image from 'components/ui/image'
import AnchorLink from 'components/ui/anchor-link'
import RenderBlock from 'components/ui/block-text'

const Footer = () => {
  const {
    sanityAppearance: {
      tab: { footer: footerSection },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      sanityAppearance {
        tab {
          footer {
            ...FooterSection
          }
        }
      }
    }
  `)

  return (
    <footer className="footer py-5 py-lg-3">
      {footerSection?.enable && (
        <Image data={footerSection?.background} className="footer__bg-image" />
      )}
      <Container
        fluid
        className="footer__main-container text-center h-100 d-flex flex-column justify-content-lg-center align-items-center"
      >
        <Row className="mt-lg-4">
          <Col xs={12} className="mt-lg-3">
            <figure className="">
              {footerSection?.enable && (
                <Image data={footerSection?.logo} className="footer__logo" />
              )}
            </figure>
          </Col>
        </Row>
        <Row>
          <Col>
            {footerSection?.enable && (
              <RenderBlock
                className="footer__copyright mt-3 px-4"
                data={footerSection?._rawCopyright}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            className="pt-lg-4 d-flex flex-wrap justify-content-center mt-4 mt-lg-0"
          >
            {footerSection?.nav?.map(item => (
              <AnchorLink
                className="footer__nav-item px-2 mx-lg-3"
                key={item?._key}
                type={item?.type}
                link={item?.link}
                text={item?.text}
                target={item?.target}
                enable={item?.enable}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
